<template>
  <div class="all">
    <!-- 
          <nav-bar-back class="detail-nav">
    <div class="title" slot="title">
     <div slot="center">我要应聘</div>
    </div>
  </nav-bar-back> -->
    <nav-bar class="detail-nav">
      <div class="title" slot="center">
        <div slot="center">我要应聘</div>
      </div>
    </nav-bar>

    <div class="content">
      <div
        class="header"
        :style="{ backgroundImage: 'url(\'' + img + '\')' }"
      ></div>
      <div class="body">
        <van-cell-group>
          <van-cell>
            <p class="headerTitle">应聘者信息</p>
          </van-cell>
        </van-cell-group>
        <van-field
          v-model="name"
          rows="1"
          autosize
          label="姓名"
          type="textarea"
          class="field"
          input-align="right"
          placeholder="应聘者姓名"
        />
        <van-field
          v-model="phone"
          rows="1"
          autosize
          label="手机号码"
          type="textarea"
          class="field"
          input-align="right"
          placeholder="应聘者手机号码"
        />
        <van-field
          v-model="idCard"
          rows="1"
          autosize
          label="身份证号"
          type="textarea"
          class="field"
          input-align="right"
          placeholder="应聘者身份证号码"
        />
        <van-field
          readonly
          clickable
          label="工厂"
          input-align="right"
          placeholder="点击选择工厂"
          :value="company"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            title="选择工厂"
            value-key="company"
            :columns="factoryList"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
       <!--  <van-field
          v-model="compare"
          rows="1"
          autosize
          label="应聘单位"
          type="textarea"
          class="field"
          input-align="right"
          placeholder="应聘单位名称"
        /> -->
        <!--         <van-field
          v-model="money"
          rows="1"
          autosize
          label="期望薪资"
          type="textarea"
          class="field"
          input-align="right"
          placeholder="填写期望薪资"
        /> -->
      </div>
    </div>
    <van-button class="commit" @click="commit">提交</van-button>
  </div>
</template>

<script>
import NavBar from "common/navbar/NavBar";
// import {getVisitById,addVisitor} from "network/visitor";
//
import { apply } from "network/others";
import { applyList } from "network/others";

import {
  Cell,
  CellGroup,
  Field,
  Button,
  DatetimePicker,
  Popup,
  Picker,
  Toast,
} from "vant";
export default {
  data() {
    return {
      showPicker:false,
      id: "",
      factoryList: [],
      company:"",
      status: false,
      name: "",
      phone: "",
      idCard: "",
      compare: "",
      money: "",
      img: require("@/assets/images/commit-info/zhaoren.png"),
    };
  },
  mounted() {
    this.getFactoryList();
  },
  created() {},
  components: {
    NavBar,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [Picker.name]:Picker,
    [Popup.name]:Popup,
  },

  methods: {
     onConfirm(value) {
      this.company = value.company;
      this.showPicker = false;
      console.log(this.company);
    },
    getFactoryList() {
      applyList().then((res) => {
        this.factoryList = res.data;
        console.log(this.factoryList);
      });
    },
    checkPhone(phone) {
      // var phone = document.getElementById('phone').value;
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        return false;
      } else {
        return true;
      }
    },
    commit() {
      if (!this.name) {
        Toast.fail("应聘者姓名不能为空");
        return;
      }
      if (!this.phone) {
        Toast.fail("应聘者手机号码不能为空");
        return;
      }

      if (!this.checkPhone(this.phone)) {
        Toast.fail("手机号码格式有误");
        return;
      }

      if (!this.idCard) {
        Toast.fail("应聘者身份证号码不能为空");
        return;
      }
      if(!this.company){
        Toast.fail("应聘工厂不能为空");
        return;
      }
/*       if (!this.compare) {
        Toast.fail("应聘单位不能为空");
        return;
      } */
      /* if(!this.depName){
              Toast.fail("应聘部门不能为空")
             return
           }

             if(!this.word){
              Toast.fail("应聘职位不能为空")
             return
           } */

/*       if (!this.money) {
        Toast.fail("请填写期望薪资");
        return;
      } */
      apply(this.name, this.phone, this.idCard, this.company).then(
        (res) => {
          if (res == "填写成功") {
            this.$router.push({
              path: "/sucefulResult",
              query: { title: "我要应聘" },
            });
            //  Toast.success('提交成功')
          }
          //  console.log("应聘结果",res)
        }
      );

      //  this.status=true
    },
  },
};
</script>

<style scoped>
.commit {
  width: 100%;
  background-image: linear-gradient(
    to right,
    rgb(243, 70, 31),
    rgb(243, 40, 71)
  );
  position: fixed;
  color: #fff;
  bottom: 0;
  left: 0;
  border: none;
  /* background-color: red; */
}
.headerTitle {
  font-size: 15px;
}
.field {
  display: flex;
  align-items: center;
}
.all {
  width: 100%;
}
.content {
  width: 100%;
  margin-bottom: 60px;
}
.header {
  height: 130px;
  background-repeat: no-repeat;
  background-size: 100%;
  /* background-image: url("~assets/images/commit-info/zhaoren.png"); */
}
</style>
// 